import compareReducer from './compare';
import contentCardsReducer from './contentCards';
import franchiseListReducer from './franchiseList';
import dealerCodesReducer from './dealerCodes';

export default {
	compareVehicles: compareReducer,
	contentCards: contentCardsReducer,
	franchiseList: franchiseListReducer,
	dealerCodes: dealerCodesReducer
};
