import { compose } from './compose';

// Return a new array of images based on the imagesToDisplay array
// arguments:
//  - imagesToDisplay: [image...]
// - galleryContent: {primary: [image...], secondary: [image...], last: [image...], insertMethod: string}
// - vin: string
export const insertWIAPIImagesByVin = (
	imagesToDisplay,
	galleryContent,
	vin
) => {
	// If galleryContent doesn't exist, return imagesToDisplay array unchanged
	if (!galleryContent) {
		return imagesToDisplay;
	}

	// if galleryContent is empty, return imagesToDisplay array unchanged
	if (
		galleryContent.primary.length === 0 &&
		galleryContent.secondary.length === 0 &&
		galleryContent.last.length === 0
	) {
		return imagesToDisplay;
	}

	// .reduce() - check type and vin then push into the return with the correct schema
	const filterGalleryContentToImageByVin = (acc, imgObj) => {
		// check matching img type and vin
		if (imgObj.type === 'image' && imgObj.vin === vin) {
			// push a converted imgObj into the return
			acc.push({
				uri: imgObj.src,
				thumbnail: imgObj.thumbnail || imgObj.src
			});
		}
		return acc;
	};

	// build primary, secondary and tertiary image arrays
	const primaryImages = galleryContent.primary.reduce(
		filterGalleryContentToImageByVin,
		[]
	);
	const secondaryImages = galleryContent.secondary.reduce(
		filterGalleryContentToImageByVin,
		[]
	);
	const lastImages = galleryContent.last.reduce(
		filterGalleryContentToImageByVin,
		[]
	);

	// init array of changes to perform on the imagesToDisplay array
	// First in Last out
	const compositionArray = [];

	// Execution order: 3
	// Append lastImages to images
	if (lastImages.length > 0) {
		compositionArray.push((images) => {
			// a .push() fn would actually mutate the array
			// destructuring the array does not mutate the original images
			return [...images, ...lastImages];
		});
	}
	// Execution order: 2
	// prepend primaryImages to images
	if (primaryImages.length > 0) {
		compositionArray.push((images) => {
			// an .unshift() fn would actually mutate the array
			// destructuring the array does not mutate the original images
			return [...primaryImages, ...images];
		});
	}
	// Execution order: 1
	// splice in secondaryImages to images
	if (secondaryImages.length > 0) {
		compositionArray.push((images) => {
			// a .splice() fn would actually mutate the array
			// using splice and destructuring the array does not mutate the original images
			let imagesArray;

			if (images.length > 0) {
				// set array with "spliced" in secondaryImages if existing images exist
				imagesArray = [
					images[0],
					...secondaryImages,
					...images.slice(1, images.length)
				];
			} else {
				// set array to the secondaryImages array
				imagesArray = secondaryImages;
			}
			return imagesArray;
		});
	}

	let baseImages = imagesToDisplay;
	// clear out images to display if the insertment is replace
	if (galleryContent.insertMethod === 'replace') {
		baseImages = [];
	}

	// return a new array of images based on execution of function in compositionArray on imagesToDisplay
	return compose(...compositionArray)(baseImages);
};

// return a new array of inventory vehicles with 3rd party content inserted
// arguments:
// - inventory: [vehicle...]
// - galleryContent: {primary: [image...], secondary: [image...], last: [image...]}
export const insertWIAPIImagesByInventory = (inventory, galleryContent) => {
	// If galleryContent doesn't exist, return inventory unchanged
	if (!galleryContent) {
		return inventory;
	}

	// return a new inventory array with vehicles updated with 3rd party image content
	const updatedInventory = inventory.map((vehicle) => {
		// get the vin of the current vehicle
		const { vin } =
			window?.DDC?.dataLayer?.vehicles?.find(
				(obj) => obj.uuid === vehicle.uuid
			) || {};
		// build a new array of images with 3rd party content inserted
		const updatedVehicleImages = insertWIAPIImagesByVin(
			vehicle.images,
			galleryContent[vin],
			vin
		);
		// return the updated vehicle with new images
		return { ...vehicle, images: updatedVehicleImages };
	});

	// return a new array of inventory vehicles
	return updatedInventory;
};
