/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	dealerCodes: {}
};

export const dealerCodesSlice = createSlice({
	name: 'dealerCodes',
	initialState,
	reducers: {
		setDealerCodes(state, action) {
			state.dealerCodes = action.payload;
		}
	}
});

export const { setDealerCodes } = dealerCodesSlice.actions;

export default dealerCodesSlice.reducer;
