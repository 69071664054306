import {
	isWindowStickerSupported,
	hasRequiredDealerCode
} from 'wsm-window-sticker';

export const validateWindowStickerCondition = (
	veh,
	pref,
	franchiseList,
	dealerCodes
) => {
	if (pref === 'none') return false;

	if (!franchiseList.includes(veh.make.toLowerCase())) return false;

	if (!isWindowStickerSupported(veh.make)) return false;

	if (!hasRequiredDealerCode(veh, dealerCodes)) return false;

	switch (pref) {
		case 'new':
			return veh.type === 2 || veh.type === 'new';

		case 'used':
			return veh.type === 1 || veh.type === 'used';

		case 'certified':
			return (veh.type === 1 || veh.type === 'used') && veh.certified;

		case 'new,certified':
			return (
				veh.type === 2 ||
				veh.type === 'new' ||
				((veh.type === 1 || veh.type === 'used') && veh.certified)
			);

		case 'new,used':
			return true;

		default:
			return false;
	}
};
